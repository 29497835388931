@tailwind base;
@tailwind utilities;
@tailwind components;

@layer base {
  :root {
    --cookie-hint-height: 0px;
    --download-board-height: 0px;

    --branding: #fa7f29;
    --community: #3e874e;

    /* colors */
    --label-l1: #314146;
    --label-l2: rgba(40, 70, 80, 0.6);
    --label-l3: rgba(40, 70, 80, 0.3);
    --primary-p1: #4c8eb4;
    --primary-p2: rgba(76, 142, 180, 0.12);
    --primary-on-primary: #fafdff;
    --secondary-p1: #4c8eb4;
    --secondary-p2: rgba(76, 142, 180, 0.12);
    --secondary-on-secondary: #fafdff;
    --third-p1: #ff765c;
    --third-p2: rgba(255, 120, 10, 0.08);
    --third-on-third: #fafdff;
    --playseeGray-gray1: #314146;
    --playseeGray-gray2: #516166;
    --playseeGray-gray3: #93a7ae;
    --playseeGray-gray4: #cae0e8;
    --playseeGray-gray5: #fafdff;
    --background-b1: #f2f4f5;
    --background-1st: #ffffff;
    --background-2nd: #f2f4f5;
    --background-elevated-b1: #f2f4f5;
    --background-elevated-1st: #ffffff;
    --background-elevated-2nd: #f2f4f5;
    --floating: #ffffff;
    --alert: #e32020;

    --label-still-l1: #fafdff;
    --label-still-l2: rgba(232, 249, 252, 0.6);
    --label-still-l3: rgba(232, 249, 252, 0.3);
    --primary-still-p1: #358bbe;
    --primary-still-p2: rgba(53, 139, 190, 0.25);
    --primary-still-on-primary: #fafdff;
    --secondary-still-p1: #358bbe;
    --secondary-still-p2: rgba(53, 139, 190, 0.25);
    --secondary-still-on-secondary: #fafdff;
    --third-still-p1: #ff765c;
    --third-still-p2: rgba(255, 204, 163, 0.16);
    --third-still-on-third: #fafdff;
    --playseeGray-still-gray1: #fafdff;
    --playseeGray-still-gray2: #cae0e8;
    --playseeGray-still-gray3: #93a7ae;
    --playseeGray-still-gray4: #516166;
    --playseeGray-still-gray5: #314146;
    --background-still-b1: #0c1212;
    --background-still-1st: #202525;
    --background-still-2nd: #353a3a;
    --background-still-elevated-b1: #202525;
    --background-still-elevated-1st: #353a3a;
    --background-still-elevated-2nd: #4b5050;
    --floating-still: #515656;
    --alert-still: #e32020;

    --overlay-light: rgba(0, 5, 5, 0.3);
    --overlay-still: rgba(0, 5, 5, 0.6);
    --overlay-thick: rgba(0, 5, 5, 0.85);

    --glass-special-alpha-0: ;
    --glass-special-tips: rgba(195, 213, 220, 0.4);
    --glass-special-options: rgba(180, 194, 200, 0.15);
    --glass-special-separators: rgba(180, 194, 200, 0.2);
    --glass-ios-20blur-light: rgba(247, 249, 250, 0.75);
    --glass-ios-20blur-still: rgba(69, 74, 74, 0.7);
    --glass-android-light: rgba(255, 255, 255, 0.95);
    --glass-android-still: rgba(69, 74, 74, 0.7);

    /* font size */
    --font-size-3xl: 30px;
    --font-size-2xl: 20px;
    --font-size-xl: 18px;
    --font-size-lg: 16px;
    --font-size-md: 14px;
    --font-size-sm: 12px;
    --font-size-xs: 10px;

    /* line height */
    --line-height-3xl: 36px;
    --line-height-2xl: 24px;
    --line-height-xl: 22px;
    --line-height-lg: 20px;
    --line-height-md: 18px;
    --line-height-sm: 16px;
    --line-height-xs: 12px;

    --shadow-floating-button: 0px 12px 24px rgba(0, 0, 0, 0.07),
      0px 0px 2px rgba(0, 0, 0, 0.06);
    --shadow-floating-dropdown: 0px 0px 12px rgba(0, 0, 0, 0.07),
      0px 0px 4px rgba(0, 0, 0, 0.04);
    --shadow-toast: 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
    --shadow-pixie-input: 0px 0px 4px 0px rgba(0, 0, 0, 0.04),
      0px 0px 12px 0px rgba(0, 0, 0, 0.07);

    --drop-shadow-repost: 0px 1px 3px rgba(0, 0, 0, 0.12),
      0px 0px 2px rgba(0, 0, 0, 0.12);
    --drop-shadow-text: 0px 0px 2px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.12);

    --gradient-post: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 8%,
      rgba(0, 0, 0, 0.38) 16%,
      rgba(0, 0, 0, 0.36) 23.8%,
      rgba(0, 0, 0, 0.33) 31.6%,
      rgba(0, 0, 0, 0.3) 39.3%,
      rgba(0, 0, 0, 0.26) 46.7%,
      rgba(0, 0, 0, 0.22) 54%,
      rgba(0, 0, 0, 0.18) 61%,
      rgba(0, 0, 0, 0.14) 67.7%,
      rgba(0, 0, 0, 0.1) 74.1%,
      rgba(0, 0, 0, 0.07) 80.1%,
      rgba(0, 0, 0, 0.04) 85.8%,
      rgba(0, 0, 0, 0.02) 91%,
      rgba(0, 0, 0, 0) 95.7%,
      rgba(0, 0, 0, 0) 100%
    );
    --gradient-feed-picker: linear-gradient(
      180deg,
      #000000 0%,
      rgba(0, 0, 0, 0.99) 7.4%,
      rgba(0, 0, 0, 0.95) 15.7%,
      rgba(0, 0, 0, 0.9) 22%,
      rgba(0, 0, 0, 0.82) 29.5%,
      rgba(0, 0, 0, 0.74) 35.3%,
      rgba(0, 0, 0, 0.65) 41.1%,
      rgba(0, 0, 0, 0.55) 47.1%,
      rgba(0, 0, 0, 0.45) 52.9%,
      rgba(0, 0, 0, 0.35) 58.9%,
      rgba(0, 0, 0, 0.26) 64.7%,
      rgba(0, 0, 0, 0.18) 70.5%,
      rgba(0, 0, 0, 0.1) 78%,
      rgba(0, 0, 0, 0.05) 84.3%,
      rgba(0, 0, 0, 0.01) 92.6%,
      rgba(0, 0, 0, 0) 100%
    );
    --gradient-community-picker: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.99) 7.4%,
      rgba(255, 255, 255, 0.95) 15.7%,
      rgba(255, 255, 255, 0.9) 22%,
      rgba(255, 255, 255, 0.82) 29.5%,
      rgba(255, 255, 255, 0.74) 35.3%,
      rgba(255, 255, 255, 0.65) 41.1%,
      rgba(255, 255, 255, 0.55) 47.1%,
      rgba(255, 255, 255, 0.45) 52.9%,
      rgba(255, 255, 255, 0.35) 58.9%,
      rgba(255, 255, 255, 0.26) 64.7%,
      rgba(255, 255, 255, 0.18) 70.5%,
      rgba(255, 255, 255, 0.1) 78%,
      rgba(255, 255, 255, 0.05) 84.3%,
      rgba(255, 255, 255, 0.01) 92.6%,
      rgba(255, 255, 255, 0) 100%
    );
    --gradient-home-header: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 8%,
      rgba(0, 0, 0, 0.13) 28.2%,
      rgba(0, 0, 0, 0.11) 39.3%,
      rgba(0, 0, 0, 0.08) 55.4%,
      rgba(0, 0, 0, 0.05) 67.7%,
      rgba(0, 0, 0, 0.01) 87.7%,
      rgba(0, 0, 0, 0) 100%
    );

    --z-index-header: 10;
    --z-index-mobile-nav: 10;
    --z-index-modal: 20;
    --z-index-modal-1: calc(var(--z-index-modal) - 1);
    --z-index-alert: 25;
    --z-index-alert-1: calc(var(--z-index-alert) - 1);
    --z-index-popover: 30;
    --z-index-popover-1: calc(var(--z-index-popover) - 1);
    --z-index-download: 30;
    --z-index-toast: 9999;

    --fixed-top: 64px;
  }
}

@layer components {
  .spot-section {
    padding: 48px 24px;
  }

  @media (min-width: 850px) {
    .spot-section {
      padding: 0;
      height: 422px;
      display: grid;
      grid-template-areas: '. . .' '. content .' '. . .';
      grid-template-rows: minmax(0, 96px) max-content minmax(0, 96px);
      grid-template-columns: 32px minmax(360px, 420px) 32px;
    }
  }

  @media (min-width: 1060px) {
    .spot-section {
      grid-template-columns: minmax(32px, 96px) 420px minmax(32px, 96px);
    }
  }

  @media (min-width: 1200px) {
    .spot-section {
      grid-template-columns: 96px auto 180px;
    }
  }

  .community-section {
    padding: 48px 24px;
  }

  @media (min-width: 850px) {
    .community-section {
      padding: 0;
      display: grid;
      place-content: center;
      grid-template-areas: '. . .' '. content .' '. . .';
      grid-template-rows: minmax(0, 96px) max-content minmax(0, 96px);
      grid-template-columns: 32px minmax(0, 420px) 32px;
    }
  }

  @media (min-width: 1060px) {
    .community-section {
      grid-template-columns: minmax(32px, 96px) 420px minmax(32px, 96px);
    }
  }

  @media (min-width: 1200px) {
    .community-section {
      grid-template-columns: 180px 1fr 180px;
    }
  }
  
  .footer-content-gap {
    gap: 24px 64px;
  }
  /* Fix iOS 14 gap issue */
  /* https://ppuzio.medium.com/flexbox-gap-workaround-for-safari-on-ios-14-13-and-lower-ffcae589eb69#fab7 */
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    .footer-content-gap > *:not(:last-child) {
      margin-right: 64px;
    }
    .footer-content-gap > * {
      margin-top: 24px;
    }
    .footer-content-gap {
      margin-top: -24px;
    }
  }

  .header {
    --text-color: var(--label-still-l1);
    background: linear-gradient(180deg, #252525 0%, rgba(37, 37, 37, 0) 100%);
  }

  .header:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: white;
    opacity: 0;
    @apply transition-opacity duration-200 ease-in;
    z-index: -1;
  }

  .header.header-white {
    --text-color: var(--label-l3);
  }

  .header.header-white:before {
    opacity: 1;
  }

  /* hamburger */
  .hamburger-box {
    width: 16px;
    height: 14px;
    margin: auto;
    position: relative;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    @apply rounded-full;

    width: 16px;
    height: 2px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    background-color: var(--text-color);
  }

  .hamburger-inner::before,
  .hamburger-inner::after {
    content: '';
    display: block;
  }

  .hamburger-inner {
    top: 50%;
    display: block;
    margin-top: -2px;
  }
  .hamburger-inner:before {
    top: -6px;
  }
  .hamburger-inner:after {
    bottom: -6px;
  }

  .hamburger--squeeze .hamburger-inner {
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 75ms;
  }
  .hamburger--squeeze .hamburger-inner:before {
    transition: top 75ms ease 0.12s, opacity 75ms ease;
  }
  .hamburger--squeeze .hamburger-inner:after {
    transition: bottom 75ms ease 0.12s,
      transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .hamburger--squeeze.is-active .hamburger-inner {
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: rotate(45deg);
  }

  .hamburger--squeeze.is-active .hamburger-inner:before {
    top: 0;
    transition: top 75ms ease, opacity 75ms ease 0.12s;
    opacity: 0;
  }
  .hamburger--squeeze.is-active .hamburger-inner:after {
    bottom: 0;
    transition: bottom 75ms ease,
      transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    transform: rotate(-90deg);
  }

  /* cookie consent */
  .cookies-hint {
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);

    position: fixed;
    bottom: 0;
    margin-bottom: 20px;
    z-index: 6;

    display: grid;
    grid-template-columns: 1fr 1px fit-content;
    gap: 12px;
    align-items: center;
    grid-template-areas: 'text ok';
    backdrop-filter: blur(20px);
    background-color: var(--label-l3);
    color: var(--label-still-l1);

    padding: 10px 12px;
    width: 340px;
    border-radius: 12px;
    margin: 16px;
  }

  .cookies-text {
    grid-area: text;
    flex-shrink: 1;
    border-right: 1px solid var(--label-still-l3);
    padding-right: 8px;
  }

  .cookies-text a {
    color: var(--label-still-l1);
    text-decoration: underline;
  }

  .cookies-ok {
    all: unset;
    grid-area: ok;

    display: flex;
    align-items: center;
    align-self: stretch;
    height: 100%;
    cursor: pointer;
    padding: 0 8px;

    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: var(--label-still-l1);
    white-space: nowrap;
  }

  /* cookie consent mobile */
  @media screen and (max-width: 849px) {
    .cookies-hint {
      width: 100%;
      left: 0;
      bottom: 0;
      background-color: var(--playseeGray-gray4);
      color: var(--label-l2);
      border-radius: 0;
      margin: 0;
    }

    .cookies-text {
      border-color: var(--label-l3);
    }
    .cookies-text a {
      color: var(--label-1);
      text-decoration: underline;
    }

    .cookies-ok {
      color: var(--label-l1);
    }
  }
}
